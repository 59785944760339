<template>
  <div>
    HELLO
  </div>
</template>

<script>
export default {
  mounted () {
    this.$breadcrumb.setCurrent('Formats', [
      {label: 'Airsas', disabled: true},
      {label: 'Formats', disabled: true}
    ])
  }
}
</script>